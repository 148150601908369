import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import vulcan from "../images/vulcan-statue.jpg"
import comparison from "../images/comparison.jpg"
import barracks from "../images/barracks.png"

const AboutMePage = () => (
    <Layout>
        <SEO title="About Me" />
        <div className="px-2 md:px-20 lg:px-48 xl:px-64 pt-12 pb-24 font-sans text-green-main">
            <h1 className="text-xl text-center tracking-widest">About Me</h1>
            <div className="p-2">
                <p className="mt-2">I was born and raised in a northern suburb of Birmingham, Alabama, to an Anglo-Irish father and an Irish mother. At eighteen years old I moved to southeast Idaho to attend Brigham Young University-Idaho; this is where I met my husband. We are now the parents of two girls and one boy; we live in the Indianapolis area.</p>
                <p className="pt-4">I have obtained a *certificate in Family History Research from Brigham Young University-Idaho. I am a senior at said university and am completing my Bachelor of Interdisciplinary Studies degree. Family History Research is my emphasis, and I also have significant coursework in Business Management and Administrative Assistance.</p>
                <p className="pt-4">Notably, I am also member of the Association of Professional Genealogists. I am currently in the process of becoming an accredited family historian with The International Commission for the Accreditation of Professional Genealogists (or ICAPGEN).</p>
                <p className="pt-4">In the past I have worked as staff member at a satellite branch of the *Family History Library based out of Salt Lake City, Utah.</p>
                <p className="pt-4">Please feel free to view my <a className="text-green-dark underline" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/orla-mcdaniel/">LinkedIn profile</a>.</p>
                <p className="pt-4"><small>*See FAQ for more information on the standards for achieving a Certificate in Family History Research.</small></p>
                <p className="pt-4"><small>*The Family History Library is the largest genealogical library in the world and is open to the public at no charge. For more information, visit their website, <a className="text-green-dark underline" target="_blank" rel="noopener noreferrer" href="https://www.familysearch.org/en/home/about">FamilySearch</a>.</small></p>
            </div>
            <div className="flex flex-col md:flex-row mt-8">
                <figure className="md:w-1/4">
                    <img className="w-full rounded shadow" src={vulcan} alt="Vulcan Statue" title="Highsmith, C. M., photographer. (2010) Vulcan statue, Birmingham, Alabama. Alabama Birmingham United States, 2010. Retrieved from the Library of Congress. No known restrictions on publication. (https://www.loc.gov/item/2010636958/)"/>
                    <figcaption className="pt-1 text-xs">Vulcan Statue, Birmingham, Alabama</figcaption>
                </figure>
                <figure className="pt-4 md:pt-0 md:pl-6 md:w-1/4">
                    <img className="w-full rounded shadow" src={comparison} alt="Generations at a Glance" title="Orla McDaniel, Generations at Glance, 2011. Digital Image, privately held in Carmel, Indiana." />
                    <figcaption className="pt-1 text-xs">Comparison of child and his great-grandfather</figcaption>
                </figure>
                <figure className="pt-4 md:pt-0 md:pl-6 md:w-1/2">
                    <img className="w-full rounded shadow" src={barracks} alt="The Barracks" title="The Barracks, through live oak and mahogany trees, Port of Spain, Trinidad, W.I. - Tobago Trinidad Trinidad and Tobago, ca. 1900. No known restrictions on publication. (https://www.loc.gov/item/2016808519/)"/>
                    <figcaption className="pt-1 text-xs">The Barracks. A historical site in the Port of Spain, Trinidad</figcaption>
                </figure>
            </div>
        </div>
    </Layout>
)

export default AboutMePage